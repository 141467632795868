window.$ = $;

import Swiper from 'swiper';
import { Autoplay } from 'swiper/modules';

window.alert = function(message, type = 'danger') {
    let $toast = $(`
        <div class="toast align-items-center text-white bg-${type} border-0" role="alert" aria-live="assertive" aria-atomic="true">
            <div class="toast-body d-flex" data-bs-dismiss="toast">
                <div class='me-2'>
                    ${message}
                </div>
                <button type="button" class="btn-close btn-close-white ms-auto" data-bs-dismiss="toast" aria-label="Close"></button>
            </div>
        </div>
    `);
    $('.toast-container').prepend($toast);
    $toast.toast('show');
};

// przenosi pojedyncze znaki z końca linijki na początek następnej
export function addNbsps(el) {
    $(el).find(":not(iframe):not(style):not(script)").add(el).contents().filter((i, n) => n.nodeType == 3).each(function () {
        var startWhitespace = /^\s/.test(this.nodeValue) ? ' ' : '';
        var endWhitespace = /\s$/.test(this.nodeValue) ? ' ' : '';
        this.nodeValue = startWhitespace + (' ' + this.nodeValue + ' ').replace(/(\s\w)\s+/g, "$1\xA0").trim() + endWhitespace;
    });
};

addNbsps('body');

// $('[data-toggle="tooltip"]').tooltip();

$.fn.initJs = function () {
    $(window).trigger('init', this);
    return this;
};
Object.defineProperty($, "init", {
    set(fn) {
        $(window).on('init', function(e, el) {
            fn(el, e);
        });
    }
});
window.addEventListener('DOMContentLoaded', () => $('body').initJs());

// s hero

$('[data-time]').each(function() {

    let that = $(this),
        countDownDate = new Date(that.data('time'));

        function getDistance() {

            let now = new Date(),
                distance = countDownDate - now,
                months = Math.floor(distance / (1000 * 60 * 60 * 24 * 30)),
                days = Math.floor((distance % (1000 * 60 * 60 * 24 * 30)) / (1000 * 60 * 60 * 24)),
                hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
                minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
                seconds = Math.floor((distance % (1000 * 60)) / 1000);

        if(months < 10) {
            months = '0' + months;
        }

        if(days < 10) {
            days = '0' + days;
        }

        if(hours < 10) {
            hours = '0' + hours;
        }

        if(minutes < 10) {
            minutes = '0' + minutes;
        }

        if(seconds < 10) {
            seconds = '0' + seconds;
        }

        that.find('.months').children('.value').html(months);
        that.find('.days').children('.value').html(days);
        that.find('.hours').children('.value').html(hours);
        that.find('.minutes').children('.value').html(minutes);
        that.find('.seconds').children('.value').html(seconds);

        if(distance < 0) {
            clearInterval(x);
            that.find('.value').html('00');
        }

    }

    var x = setInterval(function(){
        getDistance();
    }, 1000);

    getDistance();

});

// s games

let sGamesSlider;

$('.s-games__slider .swiper').each(function(){

    sGamesSlider = new Swiper($(this)[0], {
        slidesPerView: 'auto',
        speed: 1500,
        loop: true,
        autoplay: {
            delay: 1500
        },
        touchRatio: 0.1,
        modules: [Autoplay]
    });

});